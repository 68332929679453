<template>
  <v-container fluid>
    <v-card-title>
      생선 지급
      <v-spacer />
      <v-btn color="#7f00ff" dark @click="addFishToUser">
        일괄 지급
      </v-btn>
    </v-card-title>
    <v-card outlined>
      <v-list-item>
        <v-list-item-content>
          <v-row>
            <v-col cols="6">
              <v-list-item-title>
                생선 수
                <div style="margin: -16px 80px 0px; font-size: 13px">
                  지급할 생선 수를 입력해주세요.
                </div>
              </v-list-item-title>
              <v-list-item-content>
                <v-text-field v-model="req.fish" type="number" outlined dense hide-details />
              </v-list-item-content>
            </v-col>
            <v-col cols="6">
              <v-list-item-title>
                타입 선택
                <div style="margin: -16px 80px 0px; font-size: 13px">
                  생선 지급 타입을 선택해주세요.
                </div>
              </v-list-item-title>
              <v-list-item-content>
                <v-btn-toggle v-model="req.type" mandatory color="#9146ff" dense>
                  <v-btn value="BASIC">
                    BASIC
                  </v-btn>
                  <v-btn value="EVENT">
                    EVENT
                  </v-btn>
                </v-btn-toggle>
              </v-list-item-content>
            </v-col>
          </v-row>
          <v-list-item-content>
            <v-list-item-title>
              푸시 메시지 문구
              <span class="ml-4" style="font-size: 13px">
                푸시 메시지에 노출될 문구를 입력해주세요.
              </span>
            </v-list-item-title>
            <div>
              <v-text-field
                v-model="req.notificationInput.message.title"
                class="mt-2"
                placeholder="푸시 메시지 문구를 입력해주세요."
                outlined
                hide-details
                dense
              />
            </div>
            <v-textarea
              v-model="req.notificationInput.message.content"
              no-resize
              class="mt-2"
              placeholder="푸시 메시지 하단 문구를 입력해주세요."
              outlined
              hide-details
              dense
            />
          </v-list-item-content>
          <v-list-item-title>
            유저 선택
            <div style="margin: -16px 80px 0px; font-size: 13px">
              유저의 이름을 검색하고 추가를 눌러주세요.
            </div>
          </v-list-item-title>
          <v-list-item-content>
            <UserInput v-model="user" />
            <v-btn color="#ffd600" light depressed @click="addUserList">
              추가
            </v-btn>
            <div v-for="(item, index) in req.userIdList" :key="index" class="mt-3">
              {{ index + 1 }}. {{ item.name }}
              <v-btn light class="ml-3 mb-1" rounded small color="#ffd600" @click="req.userIdList.splice(index, 1)" v-text="'삭제'" />
            </div>
          </v-list-item-content>
        </v-list-item-content>
      </v-list-item>
    </v-card>

    <v-snackbar v-model="snackbar" timeout="2500">
      항목을 선택하거나 정확히 입력해주세요.
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { defineComponent, reactive, toRefs } from '@vue/composition-api'
import UserInput from '@/components/input/User.vue'

export default defineComponent({
  components: {
    UserInput,
  },

  setup(props, { root }) {
    const state = reactive({
      snackbar: false,
      fishErrorUserList: [],
      user: null,
      req: {
        type: 'BASIC',
        fish: 0,
        userIdList: [],
        notificationInput: {
          message: {
            title: null,
            content: null,
          },
        },
      },
    })

    const addFishToUser = async () => {
      try {
        const res = await root.$store.dispatch('fish/addFishToUser', {
          ...state.req,
          fish: Number(state.req.fish),
          userIdList: state.req.userIdList.map((e) => (e = Number(e.id))),
        })

        state.req = {
          type: 'BASIC',
          fish: 0,
          userIdList: [],
          notificationInput: {
            message: {
              title: null,
              content: null,
            },
          },
        }

        if (res) alert('지급 완료!')
        else alert('지급 실패!')
      } catch (err) {
        alert(err)
      }
    }

    const addUserList = () => {
      if (!state.user) return (state.snackbar = true)

      if (!state.req.userIdList.find((e) => e === state.user)) state.req.userIdList.push(state.user)
      state.user = null
    }

    return {
      ...toRefs(state),
      addUserList,
      addFishToUser,
    }
  },
})
</script>
